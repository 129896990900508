import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import styled from 'styled-components'
import {
  Title,
  Text,
  FilterCard,
  AlarmCard,
  ColumnDef,
  Table,
  Button,
  Icon,
  SharedIcon
} from '../../sharedComponents/components'
import {colors} from '../../sharedComponents/colors'
import {isToday} from 'date-fns'
import {useNavigation} from 'react-navi'
import {Helmet} from 'react-navi-helmet-async'
import {OrgLevelType} from '../../state/state'
import {
  getHumanizedTimeString,
  getHumanizedDateString,
  getHumanizedDateWithTime,
  parseStringToDate
} from '../../Components/Atoms/Utils'
import AllSitesView from '../../Components/Views/Site/AllSitesView'
import {FullScreenMainLayout, MainContent} from '../../Components/Layout/Layout'
import CoolingProgress, {isCoolingInProgress, CoolingWarning} from '../tasks/GeneralTasks/CoolingProgress'
import {InprogressTodo, TableDivider, hasInprogressTodo} from '../tasks/TodoTasks'
import {useAppState} from '../../state'
import {TaskModal} from '../tasks/TaskModal'
import {PerformableTask} from '../../state/performTask/state'
import {UnscheduledTaskModal} from './UnscheduledTaskModal'
import {ResolveAlarmModal} from '../../modals/ResolveAlarmModal'
import {Loader} from '../../Routes'
import {ErrorMessage} from '../../Components/Molecules/Errors/ErrorMessage'
import {UmbrellaCategory, TaskListItemData} from '../../state/home/site/state'
import {getDefaultUmbrellaCategory, getTaskUmbrellaColor, translateUmbrellaTypes} from './UnscheduledTaskModal'
import {translateTaskGroupName, hasCoolingEventTarget} from '../../config/utils'
import {
  alarmIconPicker,
  alarmLimitUnit,
  alarmStatus,
  alarmSubtypeToTranslation,
  alarmTypeToTranslation,
  alarmUnit,
  alarmValueFormatter,
  Units
} from '../alarms/formatters'
import {taskGroupIconPicker} from '../tasks/utils'
import {camelCase} from 'lodash'
import {CenteredLayout} from '../common/centeredLayout'
import {IN_PROGRESS_AUTOMATIC_COOLING_TASKS_UPDATE_INTERVAL} from '../tasks'

export const HomePage = () => {
  const {state, actions} = useAppState()

  const {t} = useTranslation(['home', 'tasks', 'alarms'])
  const nav = useNavigation()
  const units: Units = {
    temperature: state.site!.temperatureUnit,
    weight: state.site!.weightUnit
  }

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isNewTaskModalOpen, setNewTaskModalOpen] = useState(false)
  const [selectedAlarmId, setSelectedAlarmId] = useState<string | undefined>(undefined)
  const [task, setTask] = useState<PerformableTask | null>(null)
  const [selectedFilter, setSelectedFilters] = useState<UmbrellaCategory[]>([])

  const isFilterSelected = (key: UmbrellaCategory) => {
    return selectedFilter.includes(key)
  }

  const onClickFilter = (key: UmbrellaCategory) => {
    if (isFilterSelected(key)) {
      setSelectedFilters(selectedFilter.filter(item => item !== key))
    } else {
      setSelectedFilters([...selectedFilter, key])
    }
  }

  const {tasks, activeAlarms, pending, error} = state.v1.home.site

  useEffect(() => {
    if (state.orgLevel?.type === OrgLevelType.SITE) {
      actions.v1.home.site.getInitialHomeData({siteId: state.site!.id})
    }
  }, [actions.v1.home.site, state.site, state.orgLevel])

  const site = state.site!
  const locale = state.site!.locale

  useEffect(() => {
    const intervalId = window.setInterval(() => {
      actions.v1.home.site.updateHomeInProgressAutomaticCoolingTasks({siteId: site.id})
    }, IN_PROGRESS_AUTOMATIC_COOLING_TASKS_UPDATE_INTERVAL)
    return () => {
      window.clearInterval(intervalId)
    }
  }, [])

  if (state.orgLevel!.type !== OrgLevelType.SITE) {
    return (
      <FullScreenMainLayout data-cy="chefstein-home-page">
        <Helmet title={t('common:routes.homePage', 'Home page')}></Helmet>
        <MainContent>
          <AllSitesView />
        </MainContent>
      </FullScreenMainLayout>
    )
  }

  const countTasksByUmbrella = (umbrellaCategory: UmbrellaCategory): number => {
    return [...tasks.todaysTasks, ...tasks.inProgressTasks, ...tasks.lateTasks].filter(
      t => t.taskGroup.umbrellaCategory === umbrellaCategory
    ).length
  }

  const filterTasksByUmbrella = (tasks: TaskListItemData[]) => {
    return selectedFilter.length === 0
      ? tasks
      : tasks.filter(task => selectedFilter.includes(task.taskGroup.umbrellaCategory))
  }

  const handleClickRow = async (task: any) => {
    const {id, eventId, scheduleInstance, alarms} = task
    const data = await actions.v1.performTask.getTask({
      siteId: state.site!.id,
      taskId: id,
      eventId: eventId
    })
    if (data) {
      setTask({...data, alarms, scheduleInstance})
    }
    setIsModalOpen(true)
  }

  const onCloseApplianceAlarmModal = () => {
    setSelectedAlarmId(undefined)
  }

  const onAlarmResolveButtonClick = async (task: any, alarmId?: string) => {
    if (task) {
      const {id, eventId, scheduleInstance, alarms} = task
      const data = await actions.v1.performTask.getTask({
        siteId: state.site!.id,
        taskId: id,
        eventId: eventId
      })
      if (data) {
        setTask({...data, alarms, scheduleInstance})
      }
    }
    if (alarmId) {
      setSelectedAlarmId(alarmId)
    }
  }

  const handlePerformTask = (task: PerformableTask) => {
    setTask(task)
    setIsModalOpen(true)
  }

  const hasNoTasksToShow = tasks.count.late === 0 && tasks.count.inProgress === 0 && tasks.count.today === 0

  const columnDefs = [
    {
      title: undefined,
      dataKeys: [
        'taskGroup',
        'taskType',
        'status',
        'coolingStartedAt',
        'startTime',
        'targetTime',
        'alarms',
        'cycleLog',
        'latestValue',
        'coolingTimeLimit'
      ],
      widthPercentage: '4.5rem',
      component: (props: any) => (
        // TODO: replace 40+ task group icons in the shared components (same in the task setting page)
        <div style={{display: 'flex', paddingLeft: '1rem'}}>
          {isCoolingInProgress(props?.values[1].id, props?.values[2]) && !props.values[7] ? (
            <CoolingProgress
              startTime={props.values[3] || props.values[4]}
              automaticCoolingEndTime={props.values[7]?.endedAt}
              targetTime={props.values[5]}
              timeLimit={props.values[9]}
              alarms={props.values[6]}
              circleWidth="3em"
              iconWidth="2em"
              fontSize="XXS"
              temperatureValue={props.values[8]?.value}
            />
          ) : (
            <TaskGroupIcon taskGroup={props.values[0]} />
          )}
        </div>
      )
    },
    {
      title: t('tasks:labels.columnNameTaskName', 'Name'),
      dataKeys: [
        'name',
        'taskType',
        'status',
        'targetTime',
        'taskGroup',
        'coolingTimeLimit',
        'alarms',
        'suggestions',
        'subtasks',
        'doneSubtasks'
      ],
      component: (props: any) => (
        <div>
          <div style={{display: 'flex', flexDirection: 'column'}}>
            <Text size="XS" strong={props.selected} color={colors.system.grey_50} textTransform="uppercase">
              {`${translateUmbrellaTypes(props.values[4].umbrellaCategory)} - ${translateTaskGroupName(
                props.values[4].name
              )}`}
            </Text>
            <div style={{margin: '0.25rem 0'}}>
              <Text size="M" strong={props.selected}>
                {props.values[0]}
                {props.values[5] && ` - ${props.values[5]} min`}
              </Text>
              {hasCoolingEventTarget(props.values[1], props.values[7]) && (
                <BlockLabelText size="S">{props.values[7][0]}</BlockLabelText>
              )}
            </div>
          </div>
          {isCoolingInProgress(props?.values[1].id, props?.values[2]) && (
            <CoolingWarning
              taskTypeId={props?.values[1].id}
              targetTime={props?.values[3]}
              alarms={props?.values[6]}
              hasMeasurementTarget={hasCoolingEventTarget(props.values[1], props.values[7])}
            />
          )}
          {hasInprogressTodo(props.values[8], props.values[9]) && (
            <InprogressTodo subtasks={props.values[8]} doneSubtasks={props.values[9]} selected={props.selected} />
          )}
        </div>
      )
    },
    {
      title: t('tasks:labels.columnNameDateTime', 'Date & time'),
      dataKeys: ['scheduleInstance', 'createdAt'],
      widthPercentage: '15%',
      component: (props: any) => (
        <Text size="M" strong={props.selected} style={{float: 'right', paddingRight: '1.5rem'}}>
          {isToday(parseStringToDate(props.values[0] || props.values[1]))
            ? getHumanizedTimeString(parseStringToDate(props.values[0] || props.values[1]), locale)
            : getHumanizedDateString(parseStringToDate(props.values[0] || props.values[1]), locale)}
        </Text>
      )
    }
  ] as ColumnDef[]

  const titleForModal = () => {
    const alarm = activeAlarms.alarms.find(a => a.id === selectedAlarmId)
    if (alarm) {
      if (alarm.category === 'task') {
        return t('alarms:modal.title.taskAlarm', 'Task Alarm')
      } else {
        return t('alarms:modal.title.applianceAlarm', 'Appliance Alarm')
      }
    }
    return 'N/A'
  }

  const noTasksPlaceholder = (message: string) => {
    return (
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '5rem auto',
          alignItems: 'center',
          justifyContent: 'start',
          padding: '1.5rem 0'
        }}
      >
        <div>
          <Icon type="checklist" width="2em" height="2em" fill={colors.system.grey_50} />
        </div>
        <Text data-cy="no-tasks-placeholder" size="S" color={colors.system.grey_50}>
          {message}
        </Text>
      </div>
    )
  }

  if (pending) {
    return <Loader show={pending} />
  } else if (error) {
    return <ErrorMessage message={error.message} />
  } else {
    return (
      <CenteredLayout>
        <Helmet title={t('common:routes.homePage', 'Home page')}></Helmet>
        <HomePageContainer data-cy="chefstein-home-page">
          <SubContainer>
            <FiltersContainer>
              <Title level={5} data-cy="home-page-title">
                {t('home:labels.greeting.general', 'Welcome to Chefstein®, take a look at your day!')}
              </Title>
              <Filters>
                {getDefaultUmbrellaCategory().map(t => (
                  <FilterCard
                    key={t.key}
                    title={t.name}
                    count={countTasksByUmbrella(t.key)}
                    themeColor={t.color}
                    selected={isFilterSelected(t.key)}
                    onClick={() => onClickFilter(t.key)}
                    data-cy={`filter-umbrella-${t.key}`}
                  />
                ))}
              </Filters>
            </FiltersContainer>
            <TitleContainer>
              <div>
                <Title level={5} strong>
                  {t('tasks:labels.tabHeadingToDo', 'To-do list')}
                </Title>
                {!hasNoTasksToShow && (
                  <Text size="M" light>
                    {t('tasks:labels.tabHeadingToDoMessage', 'Here are your upcoming scheduled tasks')}
                  </Text>
                )}
              </div>
              <Button
                buttonStyle="secondary"
                buttonSize="small"
                onClick={() => setNewTaskModalOpen(true)}
                style={{display: 'flex', gap: '0.3rem', padding: '0.5rem 1.5rem 0.5rem 1.2rem'}}
                data-cy="perform-unscheduled-task-button"
              >
                {t('home:labels.new.unscheduledTask', 'Perform an unscheduled task')}
              </Button>
            </TitleContainer>
            <div style={{height: 'calc(100vh - 23rem)'}}>
              <div style={{height: '100%', marginTop: '-1px'}}>
                {!!task && <TaskModal task={task} isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />}
                <UnscheduledTaskModal
                  isOpen={isNewTaskModalOpen}
                  onClose={() => setNewTaskModalOpen(false)}
                  onPerformTask={(task: PerformableTask) => handlePerformTask(task)}
                />
                <ResolveAlarmModal
                  isOpen={selectedAlarmId !== undefined}
                  onClose={onCloseApplianceAlarmModal}
                  alarmId={selectedAlarmId}
                  onFormSubmit={async formData => {
                    if (!selectedAlarmId || !state.site) {
                      return
                    }
                    await actions.v1.alarms.resolveAlarm({
                      alarmId: selectedAlarmId,
                      resolution: formData.resolution,
                      resolvedBy: formData.resolvedBy,
                      siteId: state.site.id
                    })
                  }}
                  title={titleForModal()}
                  showApplianceLink
                />
                {hasNoTasksToShow ? (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      marginTop: '4rem'
                    }}
                  >
                    <Icon type="checklist" height="7.5em" width="7.5em" />
                    <Text size="M" strong data-cy="no-tasks-to-show-placeholder">
                      {t('home:labels.noScheduledTasks.title', 'You have no task scheduled for today.')}
                    </Text>
                    <Text size="M">
                      {t(
                        'home:labels.noScheduledTasks.suggestion',
                        'You can do an unscheduled task by creating a new task'
                      )}
                    </Text>
                  </div>
                ) : (
                  <div style={{height: '100%', overflow: 'auto'}}>
                    <TableDivider>
                      <Text data-cy="late-table-divider" size="S" strong>
                        {t('tasks:taskList.lateTasks.title', 'Late')}
                      </Text>
                      {tasks.count.late !== 0 && (
                        <Link
                          data-cy="late-table-divider-link"
                          size="S"
                          style={{float: 'right'}}
                          onClick={() => nav.navigate('/tasks')}
                          strong
                        >
                          {`${t('home:taskList.lateTasks.viewAllTasks', 'View all late tasks')} (${tasks.count.late})`}
                        </Link>
                      )}
                    </TableDivider>
                    <div>
                      {filterTasksByUmbrella(tasks.lateTasks).length > 0 ? (
                        <Table
                          columnDefs={columnDefs}
                          data={filterTasksByUmbrella(tasks.lateTasks)}
                          onClickRow={handleClickRow}
                          rowHoverBackgroundColor={colors.system.white}
                          lastRow={
                            tasks.lateTasks.length === 0 &&
                            noTasksPlaceholder(
                              t('home:taskList.lateTasks.noTasks.placeholder', 'You have no late task.')
                            )
                          }
                          hideHeader
                          data-cy="late-table"
                        />
                      ) : (
                        <NoFilteredTaskSpaceHolder />
                      )}
                    </div>
                    {tasks.count.inProgress > 0 && (
                      <>
                        <TableDivider>
                          <Text data-cy="in-progress-table-divider" size="S" strong>
                            {t('tasks:taskList.inProgress.title', 'In Progress')}
                          </Text>
                        </TableDivider>
                        <div>
                          {filterTasksByUmbrella(tasks.inProgressTasks).length > 0 ? (
                            <Table
                              columnDefs={columnDefs}
                              data={filterTasksByUmbrella(tasks.inProgressTasks)}
                              onClickRow={handleClickRow}
                              rowHoverBackgroundColor={colors.system.white}
                              hideHeader
                              data-cy="in-progress-table"
                            />
                          ) : (
                            <NoFilteredTaskSpaceHolder />
                          )}
                        </div>
                      </>
                    )}
                    <TableDivider>
                      <Text data-cy="today-table-divider" size="S" strong>
                        {t('tasks:taskList.todaysTasks.title', 'Today')}
                      </Text>
                      {tasks.count.today !== 0 && (
                        <Link size="S" style={{float: 'right'}} onClick={() => nav.navigate('/tasks')} strong>
                          {`${t('home:taskList.todaysTasks.viewAllTasks', 'View all today tasks')} (${
                            tasks.count.today
                          })`}
                        </Link>
                      )}
                    </TableDivider>
                    <div>
                      <Table
                        data-cy="today-table"
                        columnDefs={columnDefs}
                        data={filterTasksByUmbrella(tasks.todaysTasks)}
                        onClickRow={handleClickRow}
                        rowHoverBackgroundColor={colors.system.white}
                        lastRow={
                          tasks.todaysTasks.length === 0 &&
                          noTasksPlaceholder(
                            t(
                              'home:taskList.todaysTasks.noTasks.placeholder',
                              'You have no task on this list at the moment. You can find the full list from Task page.'
                            )
                          )
                        }
                        hideHeader
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </SubContainer>
          <SubContainer data-cy="alarm-container">
            <ActiveAlarmTitle data-cy="alarm-container-title">
              <Title level={5} strong>
                {t('home:labels.activeAlarm.title', 'Active alarms')}
              </Title>
              {activeAlarms.count !== 0 && (
                <Link size="M" style={{textAlign: 'center'}} onClick={() => nav.navigate('/alarms')} strong>
                  {`${t('home:labels.activeAlarm.viewAll', 'View all')} (${activeAlarms.count})`}
                </Link>
              )}
            </ActiveAlarmTitle>
            {activeAlarms.count === 0 ? (
              <NoAlarmPlaceHolder>
                <Icon type="happy-face" height="4em" width="4em" fill={colors.system.grey_3} />
                <Text size="M" color={colors.system.grey_50}>
                  {t('home:labels.activeAlarm.noAlarms.placeholder', 'All is good today, keep up the good work.')}
                </Text>
              </NoAlarmPlaceHolder>
            ) : (
              activeAlarms.alarms.map(alarm => (
                <AlarmCard
                  key={alarm.id}
                  title={alarm.name}
                  timestamp={getHumanizedDateWithTime(new Date(alarm.triggeredTime), locale)}
                  titleIcon={
                    <SharedIcon
                      icon={alarmIconPicker(alarm.type)}
                      width="2.4em"
                      height="2.4em"
                      fill={colors.system.white}
                    />
                  }
                  condition={
                    alarmSubtypeToTranslation(t, alarm.type, alarm.subtype) || alarmTypeToTranslation(t, alarm.type)
                  }
                  value={alarmValueFormatter(alarm.measuredValue, alarm.type, alarm.subtype, site)}
                  unit={alarmUnit(t, alarm.type, alarm.subtype, alarm.unit, units)}
                  minValue={alarm.limits?.minValue ?? undefined}
                  maxValue={alarm.limits?.maxValue ?? undefined}
                  minUnit={alarmLimitUnit(t, alarm.type, alarm.subtype, alarm.unit, units)}
                  maxUnit={alarmLimitUnit(t, alarm.type, alarm.subtype, alarm.unit, units)}
                  onClick={() => onAlarmResolveButtonClick(task, alarm.id)}
                  data-cy={`alarm-card-${camelCase(alarm.name)}`}
                  disabled={!alarm.isResolvable}
                  description={
                    !alarm.isResolvable ? alarmStatus(t, alarm.status, alarm.isResolvable, alarm.type) : undefined
                  }
                />
              ))
            )}
          </SubContainer>
        </HomePageContainer>
      </CenteredLayout>
    )
  }
}

export const TaskGroupIcon = ({taskGroup}: any) => {
  return (
    <IconWrapper backgroundColor={getTaskUmbrellaColor(taskGroup.umbrellaCategory)}>
      <SharedIcon icon={taskGroupIconPicker(taskGroup?.icon)} height="2em" width="2em" fill={colors.brand.cobalt} />
    </IconWrapper>
  )
}
export const IconWrapper = styled.div<{backgroundColor: string | undefined}>`
  background-color: ${props => props.backgroundColor};
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const BlockLabelText = styled(Text)`
  display: block;
`

const NoFilteredTaskSpaceHolder = styled.div`
  height: 1.5rem;
`

const Link = styled(Text)`
  :hover {
    text-decoration: underline;
    color: ${colors.brand.cobalt};
  }
`

const ActiveAlarmTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const NoAlarmPlaceHolder = styled.div`
  display: flex;
  gap: 1rem;
  padding: 1.2rem 1rem;
  border: 1px solid ${colors.system.grey_4};
  border-radius: 0.5rem;
  align-items: center;
`

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`

const FiltersContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const Filters = styled.div`
  display: flex;
  gap: 1rem;
  width: 100%;
`

const HomePageContainer = styled.div`
  display: grid;
  gap: 5rem;
  grid-template-columns: 2.5fr 1fr;
`

const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`
