import React from 'react'
import {ValueInputWithRange} from '../../../sharedComponents/components'
import {useTranslation} from 'react-i18next'

import InputWithTags from './InputWithTags'
import PerformTaskForm from './PerformTaskForm'
import PerformTaskTitle from './PerformTaskTitle'
import {handleFloatInput} from '../../../config/utils'
import {PerformableTask} from '../../../state/performTask/state'
import {FormContainer} from './PerformCoolerCoolingTask'

interface PerformManualTaskProps {
  task: PerformableTask
  setValue: any
  onAssetSaved: () => void
  payload: any
  handleNumberBlur: (event: any) => void
  handleRemove: () => void
}

const PerformManualTask = ({
  task,
  setValue,
  onAssetSaved,
  payload,
  handleNumberBlur,
  handleRemove
}: PerformManualTaskProps) => {
  const {t} = useTranslation('tasks')

  return (
    <FormContainer>
      <PerformTaskTitle task={task} />
      <ValueInputWithRange
        name="value-input"
        value={payload.value}
        label={t('tasks:labels.measuredValue', 'Measured value')}
        onChange={e => setValue('value', handleFloatInput(e.target.value))}
        onBlur={e => handleNumberBlur(e.target.value)}
        unit={task.unit}
        minValue={task.minValue}
        maxValue={task.maxValue}
        message={{
          success: t('tasks:inputWithRange.withinTarget', 'The value is within target limits'),
          error: t('tasks:inputWithRange.not.withInTarget', 'The value is not within target')
        }}
        required
      />
      <InputWithTags
        savedSuggestions={payload.suggestions}
        defaultSuggestions={task.defaultSuggestions || []}
        onChange={setValue}
      />
      <PerformTaskForm
        onInputChange={setValue}
        onAssetSaved={onAssetSaved}
        payload={payload}
        handleRemove={!!task.scheduleInstance ? handleRemove : undefined}
        setValue={setValue}
      />
    </FormContainer>
  )
}

export default PerformManualTask
